.Login {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-image: url(../../assets/auth/auth-bg.jpg);

  .login-wrapper {
    @include greaterThan($screen-md) {
      box-shadow: 0px 0px 20px 13px #88888821;
      position: absolute;
      top: 5%;
      bottom: 5%;
      left: 12%;
      right: 12%;
    }

    /* CSS FOR Ipad pro */ /* Portrait and Landscape */
    @media only screen and (min-device-width: 992px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
      box-shadow: 0px 0px 20px 13px #88888821;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    /* Portrait */
    @media only screen and (min-device-width: 992px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      box-shadow: 0px 0px 20px 13px #88888821;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    /* Landscape */
    @media only screen and (min-device-width: 992px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
      box-shadow: 0px 0px 20px 13px #88888821;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    /* CSS FOR Ipad pro */ /* Portrait and Landscape */
    @include lessThan($screen-md) {
      .rightContainer {
        display: none;
      }
      background-color: #fff;
    }

    .leftContainer {
      height: 100vh;
      overflow: hidden;

      @include greaterThan($screen-md) {
        height: 90vh;
      }

      @media (max-height: 570px) {
        overflow-y: scroll;
      }

      /* CSS FOR Ipad pro */ /* Portrait and Landscape */
      @media only screen and (min-device-width: 992px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
        height: 100vh;
      }

      /* Portrait */
      @media only screen and (min-device-width: 992px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
        height: 100vh;
      }

      /* Landscape */
      @media only screen and (min-device-width: 992px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
        //min-device-width:1024px
        height: 100vh;
      }
      /* CSS FOR Ipad pro */ /* Portrait and Landscape */

      .text-divider {
        display: flex;
        align-items: center;
        font-size: var(--text-sm);
        text-transform: uppercase;
        letter-spacing: 0.1em;
        --text-divider-gap: 1rem;

        &::before,
        &::after {
          content: '';
          height: 1px;
          background-color: silver;
          flex-grow: 1;
        }

        &::before {
          margin-right: var(--text-divider-gap);
        }

        &::after {
          margin-left: var(--text-divider-gap);
        }
      }

      .formContainer {
        @include greaterThan($screen-md) {
          margin-bottom: auto !important;
        }

        @include lessThan($screen-md) {
          margin-bottom: 3em !important;
        }

        .login-logo {
          background-color: $primary;

          img {
            margin-top: 4em;
            margin-bottom: 3em;
            width: 10em;
          }
        }

        .loginForm {
          margin-top: 5em;

          @media (max-height: 765px) {
            margin-top: 3em;
          }

          @media (max-height: 645px) {
            margin-top: 1.5em;
          }

          @media (max-height: 610px) {
            margin-top: unset;
          }

          form {
            label {
              color: $tertiaryText;
              font-size: $labelFontSize;
              text-transform: uppercase;
            }

            .form-check {
              label {
                text-transform: unset;
              }
            }

            .forgot-password {
              color: $secondary;
              font-size: $labelFontSize;
              background: none;
              outline: none;
              border: none;
              box-shadow: none;
              padding: 0;
            }

            .login-btn {
              background-color: $primary;
              color: $primaryText;
              height: 50px;
              font-size: 16px;
              border-radius: 4px;

              &:hover {
                background-color: $secondary;
              }
            }

            .resendEmail {
              font-size: 0.8em;
              color: #a0a2b7 !important;
              &:hover {
                cursor: pointer;
                border-bottom: 1px solid #a0a2b7;
              }
            }
          }
        }
      }
    }

    .rightContainer {
      background-color: $body-bg;
      height: 100vh;
      padding-top: 9%;
      text-align: center;
      color: white;
      overflow: hidden;

      @include greaterThan($screen-md) {
        height: 90vh;
      }

      /* CSS FOR Ipad pro */ /* Portrait and Landscape */
      @media only screen and (min-device-width: 992px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
        height: 100vh;
      }

      /* Portrait */
      @media only screen and (min-device-width: 992px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
        height: 100vh;
      }

      /* Landscape */
      @media only screen and (min-device-width: 992px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
        height: 100vh;
      }

      /* CSS FOR Ipad pro */ /* Portrait and Landscape */
      p {
        font-size: 14px;
      }

      img {
        width: 100%;
        height: 100%;
        padding-bottom: 6em;
      }
    }
  }
}
